import { useForm, SubmitHandler } from "react-hook-form";
import colors from "../../assets/colors";
import ControlledInput from "../../components/common/ControlledInput";
import CustomButton from "../../components/common/CustomButton";
import useAuth from "../../hooks/useAuth";
import logo from '../../assets/Images/the_carbon_games.png'

type Fields = {
    'email': string
    'password': string
}

function Login() {

    const { signIn } = useAuth()
    const { register, handleSubmit, formState: { errors } } = useForm<Fields>();

    const onSubmit: SubmitHandler<Fields> = (data) => signIn.mutate(data)

    return (
        <div style={{ display: 'flex', }} >
            <div style={{
                height: '100vh',
                width: '40rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                background: colors.BLACK_80
            }} >
                <div style={{ padding: 20, borderRadius: 10, background: colors.WHITE + '15', borderColor: colors.WHITE + '50', borderWidth: 1, borderStyle: 'dashed', minWidth: 400 }} >
                    <p style={{ fontWeight: 'bold', color: colors.WHITE }} >LOGIN</p>

                    <ControlledInput
                        containerStyle={{ marginTop: 15 }}
                        labelStyle={{ color: colors.WHITE }}
                        inputProps={{ style: { borderWidth: 1, borderStyle: 'solid', borderColor: colors.WHITE + '50', background: colors.WHITE + '15', color: colors.WHITE } }}
                        formProps={{ register, errors, name: 'email', options: { required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g } }}
                        label='Email' />

                    <ControlledInput
                        labelStyle={{ color: colors.WHITE }}
                        inputProps={{ type: 'password', style: { borderWidth: 1, borderStyle: 'solid', borderColor: colors.WHITE + '50', background: colors.WHITE + '15', color: colors.WHITE } }}
                        containerStyle={{ marginTop: 15 }}
                        formProps={{ register, errors, name: 'password', options: { required: true } }}
                        label='Password' />

                    <CustomButton
                        loading={signIn.isLoading}
                        onClick={handleSubmit(onSubmit)}
                        containerStyle={{ marginTop: 15 }}
                        label='SUBMIT' />
                </div>
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <img style={{ width: '60%' }} src={logo} />
            </div>
        </div>

    )
}


export default Login