import moment from 'moment';
import colors from '../../assets/colors';
import { Dimensions } from '../../common/Contstants';
import Card from '../../components/common/Card';
import CustomButton from '../../components/common/CustomButton';
import Header from '../../components/layout/Header';
import AddUniversity, { AddUniversityModalProps } from '../../components/modal/AddUniversity';
import useModal from '../../hooks/useModal';
import useUniversities from '../../hooks/useUniversities';
import { University } from '../../types/University';

function AllUniversities() {

    const { useUniversitiesListener } = useUniversities()
    const { show: showAddUniversityModal, ModalComp: AddUniversityModal } = useModal<AddUniversityModalProps>(({ hide, visible, props }) => <AddUniversity hide={hide} props={props} />)

    const { data: universities = [], } = useUniversitiesListener()

    return (


        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }} >
            {AddUniversityModal}
            <Header
                title='All Events'
                right={(
                    <CustomButton
                        onClick={showAddUniversityModal}
                        containerStyle={{ alignSelf: 'center' }}
                        label='Add Educational Institution' />
                )} />
            <div style={{ flex: 1, display: 'grid', flexDirection: 'column', alignItems: 'start', padding: 20, gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gridAutoRows: 'min-content', gridGap: 15, overflowY: 'auto', maxHeight: `calc(100vh - ${Dimensions.header_height}px)` }} >

                {universities.map((item, index) => <UniversityItem onClick={() => showAddUniversityModal({university : item})} key={index} university={item} />)}

            </div>
        </div>
    );
}

export default AllUniversities;

const UniversityItem = ({ university, onClick }: { university: University, onClick: () => void }) => {
    return (
        <Card
            onClick={onClick}
            className='hover-scale'
            style={{ padding: 15, alignItems: 'start', textAlign: 'start', cursor: 'pointer' }} >
            <p style={{ fontWeight: 'bold', marginBottom: 5 }} >{university.name}</p>
            <p className='truncate' style={{ color: colors.BLACK_60, width: '100%', fontSize: 12, textAlign: 'start' }}  >{university.location?.address}</p>
            <p style={{ fontSize: 12, padding: '3px 8px 3px 8px', marginTop: 5, backgroundColor: colors.BLACK_10, borderWidth: 1, borderColor: colors.BLACK_20, borderStyle: 'dotted', textAlign: 'start', borderRadius: 2 }} >Created at {moment(university.created_at).format('YYYY-MM-DD')}</p>
        </Card>
    )
}

