import { useLocation, useNavigate } from "react-router-dom";
import colors from "../../assets/colors";
import logo from "../../assets/Images/the_carbon_games.png";
import useAuth from "../../hooks/useAuth";
import {
  RiCalendarEventFill,
  RiLogoutBoxLine,
  RiBuilding2Line,
  RiBookLine,
} from "react-icons/ri";
import { FaWarehouse } from "react-icons/fa";
import { BiStats } from "react-icons/bi";
import { IconType } from "react-icons";

const SideBar = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: colors.BLACK_10,
        width: 275,
        padding: 10,
        borderWidth: 0,
        borderRightWidth: 1,
        borderColor: colors.BLACK_20,
        borderStyle: "solid",
      }}
    >
      <img
        style={{ height: 49, width: 200, objectFit: "contain" }}
        src={logo}
      />

      <div
        style={{ height: 1, background: colors.BLACK_20, margin: "10px -10px" }}
      />

      <div className="gap-2 flex flex-col">
        <SideBarItem
          onClick={() => navigate("/home/all-events")}
          label="EVENTS"
          selected={location.pathname.includes("all-events")}
          Icon={RiCalendarEventFill}
        />
        <SideBarItem
          onClick={() => navigate("/home/all-corporations")}
          label="CORPORATIONS"
          selected={location.pathname.includes("all-corporations")}
          Icon={RiBuilding2Line}
        />
        <SideBarItem
          onClick={() => navigate("/home/all-educational-institutions")}
          label="EDUCATIONAL INSTITUTIONS"
          selected={location.pathname.includes("all-educational-institutions")}
          Icon={RiBookLine}
        />
        <SideBarItem
          onClick={() => navigate("/home/all-warehouses")}
          label="WAREHOUSES"
          selected={location.pathname.includes("all-warehouses")}
          Icon={FaWarehouse}
        />
      </div>

      <div className="mt-auto gap-y-2 flex flex-col">
        <SideBarItem
          onClick={() => navigate("/home/stats")}
          label="STATS"
          selected={location.pathname.includes("stats")}
          Icon={BiStats}
        />
        <div
          onClick={logout}
          className="hover-border"
          style={{
            display: "flex",
            alignItems: "center",
            padding: 10,
            background: colors.RED + "20",
            borderRadius: 5,
            cursor: "pointer",
          }}
        >
          <RiLogoutBoxLine />
          <p style={{ paddingLeft: 10, fontSize: 12, fontWeight: "bold" }}>
            LOGOUT
          </p>
        </div>
      </div>
    </div>
  );
};

export default SideBar;

const SideBarItem = ({
  label,
  onClick,
  selected,
  Icon,
}: {
  label: string;
  onClick: () => void;
  selected: boolean;
  Icon: IconType;
}) => {
  return (
    <div
      onClick={onClick}
      className="hover-border"
      style={{
        display: "flex",
        alignItems: "center",
        padding: 10,
        background: selected ? colors.BLACK_20 : colors.TRANSPARENT,
        borderRadius: 5,
        cursor: "pointer",
      }}
    >
      <Icon />
      <p style={{ paddingLeft: 10, fontSize: 12, fontWeight: "bold" }}>
        {label}
      </p>
    </div>
  );
};
