import React, { useContext, useMemo } from "react";
import { createGlobalState } from "react-use";
import { storage } from "../config/firebase";

export const useUploadTasks = createGlobalState<firebase.default.storage.UploadTask[]>([])

const useStorage = () => {

    /**
     * Select file(s).
     * @param {Boolean} multiple Indicates if the user can select multiple files.
     * @returns {Promise<File|File[]>} A promise of a file or array of files in case the multiple parameter is true.
     */
    function selectFile(multiple: boolean): Promise<File[]> {
        return new Promise(resolve => {
            let input = document.createElement('input');
            input.type = 'file';
            input.multiple = multiple;
            input.accept="image/png, image/gif, image/jpeg, image/jpg"

            input.onchange = () => {
                let files = Array.from(input.files);
                resolve(files);
            };

            input.click();
        });
    }

    const uploadFile = ({file, path, onProgressChanged}:{file: File, path: string, onProgressChanged? : (progress? :number)=>void}) => {
        const reference = storage.ref(`${path}/${file.name}`)
        const task = reference.put(file)
        task.on('state_changed', (snapshot) => {
            const progress =  (snapshot.bytesTransferred * 100) / snapshot.totalBytes
            onProgressChanged?.(progress==100 ? undefined : progress)
            console.log(snapshot.totalBytes, snapshot.bytesTransferred, (snapshot.bytesTransferred * 100) / snapshot.totalBytes, snapshot.state)
        })
        return task
    }

    return {
        uploadFile,
        selectFile
    }

}

export default useStorage