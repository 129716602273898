import moment from "moment"

export default class Event {
    constructor(data?: any) {
        Object.assign(this, data)
    }

    location!: Location
    venue!: string
    date!: number
    endDate!: number
    status!: 'active' | 'inactive' | 'archived'
    name!: string
    id!: string
    subscribers!: string[]
    access!: 'locked' | 'open'
    startTime!: string
    endTime!: string
    description!: string
    code?: string
    type: 'event' = 'event'
    expectedTurnout?: { min: number, max: number }
    goals: {
        co2: number
    }
    eventLogo?: string
    images?: {
        image1: string
        image2: string
        image3: string
        image4: string
        image5: string
    } = {
            image1: null,
            image2: null,
            image3: null,
            image4: null,
            image5: null
        }
    radiuses?: {
        origin: number,
        destination: number
    }
    aggregate?: {
        credits?: number,
        co2Amount?: number
    } = { credits: 0, co2Amount: 0 }
    skin?: Skin
    minting_enabled?:boolean
    check_in_enabled?:boolean
    featured?:boolean
    leaderboards_enabled?:boolean
    meetups_enabled?:boolean

    get eventFormattedDate() {

        const startMonth = moment(this.date).format('MMMM')
        const endMonth = moment(this.endDate || this.date).format('MMMM')
        const startDate = moment(this.date).format('Do')
        const endDate = moment(this.endDate || this.date).format('Do')

        const time = this.startTime == this.endTime ? this.startTime : `${this.startTime} - ${this.endTime}`

        return `${startDate} ${startMonth == endMonth ? '' : startMonth}${endDate == startDate ? '' : ' - '}${endDate == startDate ? '' : endDate + ' '}${endMonth}, ${time}`

    }
}

export type Skin = {
    backgroundColors: string[],
    light: boolean,
    type: 'skin'
}


export type Location = {
    coordinates: Coordinate
    geohash: string
    type?: 'event',
    id?: string
    address?: string

}

export type Coordinate = { latitude: number, longitude: number }
