import { useMutation } from "@tanstack/react-query"
import { db } from "../config/firebase"
import { Corporate } from "../types/Corporate"
import Event from "../types/Event"
import useFirestoreRealtimeData from "./useFirestoreRealtimeData"

const corporationsColRef = db.collection('corporations')

const useCorporations = () => {

    return {
        useCorporationsListener: () => useFirestoreRealtimeData({
            query: corporationsColRef,
            select: snapshot => (snapshot?.docs.map(doc => ({ id: doc.id, ...doc.data() } as Corporate)) || []).sort((a, b)=>b.created_at - a.created_at)
        }),

        addCorporation: useMutation({
            mutationFn: async (data: Partial<Corporate>) => corporationsColRef.add({...data, created_at : Date.now()})
        }),

        updateCorporation: useMutation({
            mutationFn: async (data: Partial<Corporate>) => corporationsColRef.doc(data.id || 'alt').update(data)
        }),

        deleteCorporation: useMutation({
            mutationFn: async (id :string) => corporationsColRef.doc(id).delete()
        })
    }
}

export default useCorporations