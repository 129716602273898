import { useMutation } from "@tanstack/react-query";
import React, { ReactNode, useContext } from "react";
import useSubscriber from "./useSubscriber";
import { auth, db } from "../config/firebase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface AuthContextInterface {
    user: firebase.default.User|null
}

const AuthContext = React.createContext({} as AuthContextInterface)

export const AuthProvider = ({ children }: { children: ReactNode }) => {

    const { data: user } = useSubscriber((observer: (value: firebase.default.User | null) => void) => auth.onAuthStateChanged(v => observer(v)))

    return (
        <AuthContext.Provider value={{ user }} >
            {children}
        </AuthContext.Provider>
    )
}

const useAuth = () => {
    const { user } = useContext(AuthContext)
    const navigate = useNavigate()
    return {
        user,

        signIn: useMutation({
            mutationFn: async (params: { email: string, password: string }) => {
                if(await isAdmin(params.email))await auth.signInWithEmailAndPassword(params.email, params.password)
                else throw {code : 'user-not-admin'}
            },
            onError: (err: any) => {
                console.log(err.code)
                if (err.code == 'auth/user-not-found') toast('User not found', { hideProgressBar: true, type: 'error' })
                if (err.code == 'user-not-admin') toast('User not registered as admin', { hideProgressBar: true, type: 'error' })
            }
        }),

        logout: async () => {
            await auth.signOut()
            navigate('/login')
        }

    }
}

export default useAuth

const isAdmin = async (email : string)=>{
    const snapshot =await db.collection('config').doc('internal_dashboard_config').get()
    const admins : string[] =snapshot.get('admins') || []
    return admins.map(admin=>admin.toLowerCase()).includes(email.toLowerCase()) 
}