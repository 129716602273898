
import React, { useMemo } from 'react'
import { UseFormRegister, FieldErrorsImpl, Path, RegisterOptions, ControllerRenderProps, Controller, Control } from "react-hook-form";
import colors from '../../assets/colors';
import ReactSlider from 'react-slider'

type Props<FieldValues> = {
    containerStyle?: React.CSSProperties
    min: number
    max: number
    formProps: {
        errors: Partial<FieldErrorsImpl<{ [x: string]: any; }>>
        name: Path<FieldValues>
        options?: Partial<RegisterOptions>
        control: Control<any, any>
    }
}

const ControlledSlider = <FieldValues,>({ formProps, containerStyle, min, max }: Props<FieldValues>) => {
    const ControllerRender = ({ field: { onChange, onBlur, value } }: {
        field: ControllerRenderProps<any, Path<FieldValues>>
    }) => {
        return (
            <div style={containerStyle} >
                <ReactSlider
                    value={value}
                    min={min}
                    max={max}
                    onChange={(value) => onChange(value)}
                    renderMark={(props) => <div {...props} style={{ ...props.style, height: 10, background: 'red' }} />}
                    renderTrack={(props) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                zIndex: 0,
                                height: 7,
                                backgroundColor: props.key == 'track-0' ? colors.PRIMARY : colors.PRIMARY + '50',
                                borderRadius: 10
                            }} />
                    )}
                    renderThumb={(props, state) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                background: colors.BLACK_80,
                                borderWidth: 3,
                                zIndex: 0,
                                borderColor: colors.WHITE,
                                borderStyle: 'solid',
                                marginTop: -6,
                                width: 18,
                                height: 18,
                                borderRadius: 15,
                            }} />
                    )}
                />
            </div>
        )
    }

    return (
        <Controller
            name={formProps.name}
            control={formProps.control}
            rules={formProps.options}
            render={ControllerRender} />
    )


}

export default ControlledSlider