import { CSSProperties, useMemo } from "react"
import colors from "../../assets/colors"
import { shadow } from "../../assets/styles"
import { MdArrowDropDown, MdArrowDropUp, MdCancel, MdClose } from "react-icons/md";

type Props<TValue> = {
    options: Option<TValue>[]
    value?: TValue
    onSelect: (value?: TValue) => void
    style?: CSSProperties
    tabStyle?: CSSProperties
}

const Tabs = <TValue,>({ options, value, onSelect, style, tabStyle }: Props<TValue>) => {

    const OptionItem = ({ option }: { option: Option<TValue> }) => {
        const selected = useMemo(() => option.value == value, [value])
        return (
            <div
                className={`${selected ? '' : "hover-opacity"} ${selected ? 'bg-gray-700' : 'bg-gray-100'}`}
                onClick={() => onSelect(option.value)}
                style={{ padding: '6px 20px', flex: 1, cursor: 'pointer', borderRadius: 3, ...tabStyle }} >
                <p style={{ color: selected ? colors.WHITE : colors.BLACK_80, fontSize: 13, fontWeight: '500' }} >{option.label}</p>
            </div>
        )
    }

    return (
        <div
        className="border border-gray-300 p-1 gap-1"
            style={{ display: 'flex', pointerEvents: 'all', borderRadius: 5,  overflow: 'hidden', borderStyle: 'solid', backgroundColor: colors.WHITE,  ...style }} >
            {options.map((option, index) => <OptionItem option={option} key={index} />)}
        </div>
    )
}

export default Tabs

export type Option<TValue> = { label: string, value: TValue }