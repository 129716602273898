import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import colors from '../../assets/colors';
import { Dimensions } from '../../common/Contstants';
import Card from '../../components/common/Card';
import CustomButton from '../../components/common/CustomButton';
import Header from '../../components/layout/Header';
import AddCorporate, { AddCorporateModalProps } from '../../components/modal/AddCorporate';
import useCorporations from '../../hooks/useCorporations';
import useModal from '../../hooks/useModal';
import { Corporate } from '../../types/Corporate';

function AllCorporations() {
    const { useCorporationsListener } = useCorporations()
    const { show: showAddCorporateModal, ModalComp: AddCorporateModal } = useModal<AddCorporateModalProps>(({ hide, visible, props }) => <AddCorporate hide={hide} props={props} />)

    const { data: corporations = [], } = useCorporationsListener()

    return (


        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }} >
            {AddCorporateModal}
            <Header
                title='All Events'
                right={(
                    <CustomButton
                        onClick={showAddCorporateModal}
                        containerStyle={{ alignSelf: 'center' }}
                        label='Add Corporation' />
                )} />
            <div style={{ flex: 1, display: 'grid', flexDirection: 'column', alignItems: 'start', padding: 20, gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gridAutoRows: 'min-content', gridGap: 15, overflowY: 'auto', maxHeight: `calc(100vh - ${Dimensions.header_height}px)` }} >

                {corporations.map((item, index) => <CorporateItem onClick={() => showAddCorporateModal({corporate : item})} key={index} corporation={item} />)}

            </div>
        </div>
    );
}

export default AllCorporations;

const CorporateItem = ({ corporation, onClick }: { corporation: Corporate, onClick: () => void }) => {
    return (
        <Card
            onClick={onClick}
            className='hover-scale'
            style={{ padding: 15, alignItems: 'start', textAlign: 'start', cursor: 'pointer' }} >
            <p style={{ fontWeight: 'bold', marginBottom: 5 }} >{corporation.name}</p>
            <p className='truncate' style={{ color: colors.BLACK_60, width: '100%', fontSize: 12, textAlign: 'start' }}  >{corporation.branches?.length} Branches</p>
            <p style={{ fontSize: 12, padding: '3px 8px 3px 8px', marginTop: 5, backgroundColor: colors.BLACK_10, borderWidth: 1, borderColor: colors.BLACK_20, borderStyle: 'dotted', textAlign: 'start', borderRadius: 2 }} >Created at {moment(corporation.created_at).format('YYYY-MM-DD')}</p>
        </Card>
    )
}

