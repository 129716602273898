import { CSSProperties, ReactNode } from "react"
import colors from "../../assets/colors"
import Icons from "../../assets/Icons"
import ActivityIndicator from "./ActivityIndicator"
import ImageIcon, { ImageIconProps } from "./ImageIcon"

type Props = {
    label: string
    onClick?: () => void
    icon?: keyof typeof Icons
    IconComp?: ReactNode
    containerStyle?: CSSProperties
    labelStyle?: CSSProperties
    iconProps?: Omit<ImageIconProps, 'name'>
    loading?: boolean
    secondary?: boolean
    disabled? : boolean
}

const CustomButton = ({ label, onClick, icon, containerStyle, labelStyle, iconProps, IconComp, loading, secondary, disabled }: Props) => {
    return (
        <div
            onClick={loading || disabled ? undefined : onClick}
            className="hover-scale hover-opacity"
            style={{
                borderRadius: 4,
                background: secondary ? colors.BLACK_20 : colors.TURQUOISE,
                padding: '10px 20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                opacity: loading || disabled ? .7 : 1,
                ...containerStyle
            }} >
            {IconComp || (icon && !loading && <ImageIcon name={icon} {...iconProps} />)}
            {loading && <ActivityIndicator style={{ marginRight: 10 }} color={secondary ? colors.BLACK_80 : colors.WHITE} />}
            <p style={{ fontWeight: '500', color:secondary ? colors.BLACK_80 : colors.WHITE, marginLeft: (IconComp || icon) ? 6 : 0, fontSize: 14, ...labelStyle }} >{label}</p>
        </div>
    )
}

export default CustomButton