import { detectConcordiumProvider } from '@concordium/browser-wallet-api-helpers';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import colors from '../../assets/colors';
import { Dimensions } from '../../common/Contstants';
import Card from '../../components/common/Card';
import Connection from '../../components/common/connection';
import CustomButton from '../../components/common/CustomButton';
import Header from '../../components/layout/Header';
import AddEvent, { AddEventModalProps } from '../../components/modal/AddEvent';
import useEvents from '../../hooks/useEvents';
import useModal from '../../hooks/useModal';
import Event from '../../types/Event';
import Tabs from '../../components/common/Tabs';
import DropdownOption from '../../types/Common';
import Dropdown from '../../components/common/Dropdown';

const VERIFIER_URL = 'http://0.0.0.0:8100/api';

function AllEvents() {

    const navigate = useNavigate()
    const { useEventsListener } = useEvents()
    const { show: showAddEventModal, ModalComp: AddEventModal } = useModal<AddEventModalProps>(({ hide, visible, props }) => <AddEvent hide={hide} props={props} />)

    const [account, setAccount] = useState<string>();
    const [authToken, setAuthToken] = useState<string>();
    const [tab, setTab] = useState<string>('active')
    const [country, setCountry] = useState<string>('all-countries')

    const { data: events = [], } = useEventsListener({ status: tab as any })

    const countries = useMemo(() => {
        const list = events.map(event => event.location?.address.split(',').at(-1).trim()).filter((item, index, arr) => arr.indexOf(item) === index)
        return [{ value: 'all-countries', label: 'All Countries' }, ...list.map((item) => ({ value: item, label: item }))]
    }, [events])

    const filteredEvents = useMemo(() => {
        return events.filter(event => {
            if (country === 'all-countries') return true
            return event.location?.address.split(',').at(-1).trim() === country
        })
    }, [events, country])

    useEffect(() => {
        detectConcordiumProvider()
            .then((provider) => {
                // Listen for relevant events from the wallet.
                provider.on('accountChanged', setAccount);
                provider.on('accountDisconnected', () => provider.getMostRecentlySelectedAccount().then(setAccount));
                // Check if you are already connected
                provider.getMostRecentlySelectedAccount().then(setAccount);
            })
            .catch(() => setAccount(undefined));
    }, []);

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }} >
            {AddEventModal}
            <Header
                title='Events'
                leftAfterTitle={(
                    <div className='flex items-center ' >

                        <Tabs
                            onSelect={(value) => {
                                setTab(value)
                                setCountry('all-countries')
                            }}
                            value={tab}
                            options={tabs} />

                        <div className='w-[1px] bg-gray-200 mx-4 my-1 self-stretch' />

                        <Dropdown onClick={(f) => setCountry(f)} options={countries} value={country || 'all-countries'} />

                    </div>
                )}
                right={(
                    <CustomButton
                        onClick={showAddEventModal}
                        containerStyle={{ alignSelf: 'center' }}
                        label='Add Event' />
                )} />

            <div style={{ flex: 1, display: 'grid', flexDirection: 'column', alignItems: 'start', padding: 20, gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gridAutoRows: 'min-content', gridGap: 15, overflowY: 'auto', maxHeight: `calc(100vh - ${Dimensions.header_height}px)` }} >

                {filteredEvents.map((item, index) => <EventItem onClick={() => showAddEventModal({ event: item })} key={index} event={item} />)}

            </div>
        </div>
    );
}

export default AllEvents;

const EventItem = ({ event, onClick }: { event: Event, onClick: () => void }) => {
    return (
        <Card
            onClick={onClick}
            className='hover-scale'
            style={{ padding: 15, alignItems: 'start', textAlign: 'start', cursor: 'pointer' }} >
            <p style={{ fontWeight: 'bold', marginBottom: 5 }} >{event.name}</p>
            <p className='truncate' style={{ color: colors.BLACK_60, width: '100%', fontSize: 12, textAlign: 'start' }} >{event.description}</p>
            <p style={{ fontSize: 12, padding: '3px 8px 3px 8px', marginTop: 5, backgroundColor: colors.BLACK_10, borderWidth: 1, borderColor: colors.BLACK_20, borderStyle: 'dotted', textAlign: 'start', borderRadius: 2 }} >{event.eventFormattedDate}</p>
        </Card>
    )
}

const tabs = [
    { label: 'Active', value: 'active' },
    { label: 'Archived', value: 'archived' }
]


const emissionAmounts: DropdownOption[] = [
    {
        value: '1',
        label: '0-1 kgs'
    },
    {
        value: '10',
        label: '1-10 kgs'
    },
    {
        value: '20',
        label: '10-20 kgs'
    },
    {
        value: '100',
        label: '20-100 kgs'
    },
]