import { useEffect, useState } from "react"


const useSubscriber = <TFn>(
    fn: (observer: (value: TFn) => void) => (() => void | undefined),
    deps: any[] = []
) => {
    const [data, setData] = useState<TFn>()

    useEffect(() => {
        // if any of the deps are null return
        if (deps.map(d => !!d).filter(d => d == false).length > 0) return

        const unsubscribe = fn((v) => setData(v))
        return () => unsubscribe ? unsubscribe() : undefined
    }, deps)

    return { data }

}

export default useSubscriber