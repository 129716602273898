import { Dimensions } from "../../common/Contstants";
import Card from "../../components/common/Card";
import Header from "../../components/layout/Header";
import { IoMdPeople } from "react-icons/io";
import { IconType } from "react-icons";
import useUsers from "../../hooks/useUsers";

function Stats() {
  const { useAllUsersCount } = useUsers();
  const { data: allUsersCount } = useAllUsersCount();

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
      <Header title="Stats" />
      <div
        style={{
          flex: 1,
          display: "grid",
          flexDirection: "column",
          alignItems: "start",
          padding: 20,
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
          gridAutoRows: "min-content",
          gridGap: 15,
          overflowY: "auto",
          maxHeight: `calc(100vh - ${Dimensions.header_height}px)`,
        }}
      >
        {allUsersCount && <StatCard label="Total Users" value={allUsersCount} Icon={IoMdPeople} />}
      </div>
    </div>
  );
}

export default Stats;

const StatCard = ({ label, value, Icon }: { label: string; value: string | number; Icon: IconType }) => {
  return (
    <Card className="hover-scale" style={{ alignItems: "start", textAlign: "start", cursor: "pointer", flexDirection: "row", display: "flex", overflow: "hidden" }}>
      <div className="bg-gray-300 p-5">
        <Icon size={40} opacity={0.6} />
      </div>
      <div className="p-2">
        <p style={{ fontWeight: "bold" }}>{label}</p>
        <p className="text-2xl">{value}</p>
      </div>
    </Card>
  );
};
