import { Menu, MenuItem } from "@szhsin/react-menu"
import { GrDown } from "react-icons/gr"
import DropdownOption from "../../types/Common"
import ImageIcon from "./ImageIcon"
import colors from "../../assets/colors"

type Props = {
    label?: string,
    value: string,
    options: DropdownOption[],
    onClick: (value: string) => void
}

const Dropdown = ({ label, value, options, onClick }: Props) => {
    return (
        <Menu
            menuStyle={{ borderRadius: 4, boxShadow: 'none', borderWidth: 1, borderColor: colors.BLACK_20, borderStyle: 'solid',overflowY : 'auto', maxHeight : 400 }}
            menuButton={(
                <div
                    className='bg-white border border-gray-200 hover:border-white transition-all px-4 py-2 rounded'
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <p style={{ fontWeight: '500', fontSize: 14 }} >{label || options.find(o => o.value == value)?.label}</p>
                    <GrDown style={{ fontSize: 12, marginLeft: 5 }} />
                </div>
            )}
            transition>
            {options.map((option, index) => <CustomMenuItem key={index} {...option} onClick={onClick ? () => onClick(option.value) : option.onClick}  />)}
        </Menu>
    )
}

const CustomMenuItem = ({ icon, label, tint, value, onClick }: DropdownOption) => {
    return (
        <MenuItem  >
            <div onClick={onClick} style={{ display: 'flex', alignItems: 'center', gap: 5 }} >
                {icon && <ImageIcon size={18} name={icon} />}
                <p style={{ fontSize: 14, color: tint }} >{label}</p>
            </div>
        </MenuItem>
    )
}

export default Dropdown