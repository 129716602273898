import { useEffect, useRef } from "react";
import { Location, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import SideBar from "./components/layout/SideBar";
import useAuth from "./hooks/useAuth";
import Login from "./pages/auth/Login";
import AllCorporations from "./pages/corporations/AllCorporations";
import AllEvents from "./pages/events/AllEvents";
import AllStorages from "./pages/storages/AllStorages";
import AllUniversities from "./pages/universities/AllUniversities";
import Stats from "./pages/stats/Stats";

function App() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const previousPath = useRef<Location>(null);

  useEffect(() => {
    if (location.pathname !== "/" && location.pathname !== "/login") previousPath.current = location;
    if (user === undefined) navigate("");
    else if (user === null) navigate("login");
    else {
      if (previousPath.current?.pathname === "/home") return navigate("/home/all-events");
      else navigate(previousPath.current?.pathname + previousPath.current?.search || "/home/all-events");
    }
  }, [user]);

  return (
    <div style={{ height: "100vh", display: "flex" }} className="App">
      <Routes>
        <Route path="home" element={<Home />}>
          <Route path="all-events" index element={<AllEvents />} />

          <Route path="all-corporations" index element={<AllCorporations />} />

          <Route path="all-educational-institutions" index element={<AllUniversities />} />

          <Route path="all-warehouses" index element={<AllStorages />} />

          <Route path="stats" index element={<Stats />} />
        </Route>

        <Route path="login" element={<Login />} />

        <Route path="" element={<Loading />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;

const Loading = () => {
  return <p>Loading</p>;
};

const Home = () => {
  return (
    <div style={{ height: "100vh", width: "100vw", display: "flex" }} className="App">
      <SideBar />
      <div
        style={{
          flex: 1,
          flexDirection: "column",
          maxHeight: "100vh",
          overflowY: "hidden",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};
