import { ReactNode } from "react";
import colors from "../../assets/colors";
import { shadow } from "../../assets/styles";

const Card = ({ children, style, className, onClick }: {
    children?: ReactNode,
    style?: React.CSSProperties,
    className?: string,
    onClick?: () => void
}) => (
    <div
        onClick={onClick}
        className={className}
        style={{ ...shadow, borderRadius: 6, backgroundColor: colors.WHITE, display: 'flex', flexDirection: 'column', ...style }} >
        {children}
    </div>
)

export default Card