import { ReactNode } from "react"
import colors from "../../assets/colors"
import { Dimensions } from "../../common/Contstants"

const Header = ({ title, right, leftAfterTitle }: { title: string, right?: ReactNode, leftAfterTitle?: ReactNode }) => {
    return (
        <div className="gap-4" style={{ display: 'flex', background: colors.BLACK_10, borderWidth: 0, borderBottomWidth: 1, borderColor: colors.BLACK_20, borderStyle: 'solid', height: Dimensions.header_height, alignItems: 'center', padding: '0 15px', width: '100%' }} >
            <p style={{ fontWeight: 'bold', color: colors.BLACK_100, fontSize: 24, }} >{title}</p>
            {leftAfterTitle}
            <div style={{ flex: 1 }} />
            {right}
        </div>
    )
}

export default Header