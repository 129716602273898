
import React, { useMemo } from 'react'
import { UseFormRegister, FieldErrorsImpl, Path, RegisterOptions } from "react-hook-form";
import colors from '../../assets/colors';

type Props<FieldValues> = {
    containerStyle?: React.CSSProperties
    label?: string
    labelStyle?: React.CSSProperties
    inputProps?: React.InputHTMLAttributes<any>
    multiline?: boolean
    formProps: {
        register: UseFormRegister<FieldValues>,
        errors: Partial<FieldErrorsImpl<{ [x: string]: any; }>>
        name: Path<FieldValues>
        options?: Partial<RegisterOptions>
    }
}

const ControlledInput = <FieldValues,>({ label, formProps, containerStyle, inputProps, multiline , labelStyle}: Props<FieldValues>) => {

    const errorMessage = useMemo(() => {
        const error = formProps.errors[formProps.name]
        if (!error) return ''
        if (error?.message) return error.message + ''
        if (error.type == 'pattern') return `Please enter a valid ${label}`
        return `${label} is required`
    }, [formProps.errors[formProps.name]])


    return (
        <div
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', alignSelf: 'stretch', ...containerStyle }} >
            <p style={{ color: colors.BLACK_100, fontSize: 12,fontWeight : '500', marginBottom: 6, ...labelStyle }} >{label}</p>

            {
                multiline ? (
                    <textarea
                        {...formProps.register(formProps.name, formProps.options)}
                        {...inputProps}
                        style={{ backgroundColor: colors.BLUE + '12', borderRadius: 5,padding : 12,  borderWidth: 0, minWidth: 200, paddingLeft: 16, paddingRight: 16, alignSelf: 'stretch' ,...inputProps.style  }} />
                ) : (
                    <input
                        {...formProps.register(formProps.name, formProps.options)}
                        {...inputProps}
                        style={{ backgroundColor: colors.BLUE + '12', borderRadius: 5, padding: 12, borderWidth: 0, minWidth: 200, paddingLeft: 16, paddingRight: 16, alignSelf: 'stretch', ...inputProps.style }} />
                )
            }


            {errorMessage && <p style={{ color: 'red', fontSize: 12, marginTop: 5 }} >{errorMessage}</p>}

        </div>
    )
}

export default ControlledInput