import { useMutation } from "@tanstack/react-query"
import { db } from "../config/firebase"
import Storage from "../types/Storage"
import useFirestoreRealtimeData from "./useFirestoreRealtimeData"

const storagesColRef = db.collection('storages')

const useStorages = () => {

    return {
        useStoragesListener: () => useFirestoreRealtimeData({
            query: storagesColRef,
            select: snapshot => (snapshot?.docs.map(doc => ({ id: doc.id, ...doc.data() } as Storage)) || []).sort((a, b)=>b.created_at - a.created_at)
        }),

        addStorage: useMutation({
            mutationFn: async (data: Partial<Storage>) => storagesColRef.add({...data, created_at : Date.now(), status : 'active'})
        }),

        updateStorage: useMutation({
            mutationFn: async (data: Partial<Storage>) => storagesColRef.doc(data.id || 'alt').update(data)
        }),

        deleteStorage: useMutation({
            mutationFn: async (id :string) => storagesColRef.doc(id).delete()
        })
    }
}

export default useStorages