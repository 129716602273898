import { db } from "../config/firebase"
import Event from "../types/Event"
import useFirestoreRealtimeData from "./useFirestoreRealtimeData"

const eventsColRef = db.collection('events')

const useEvents = () => {
    return {
        useEventsListener: ({ status }: { status: 'active' | 'archived' }) => useFirestoreRealtimeData({
            query: status === 'active' ? eventsColRef.where('status', '==', 'active') : eventsColRef.where('status', '!=', 'active'),
            select: snapshot => (snapshot?.docs.map(doc => new Event(({ id: doc.id, ...doc.data() }))) || []).sort((a, b) => b.date - a.date),
            deps:[status]
        }),

        addEvent: (data: Partial<Event>) => eventsColRef.add(data),

        updateEvent: (data: Partial<Event>, id: string) => eventsColRef.doc(id).update(data),

        deleteEvent: (id: string) => eventsColRef.doc(id).delete(),
}
}

export default useEvents