import { SubmitHandler, useForm } from "react-hook-form"
import colors from "../../assets/colors"
import useCorporations from "../../hooks/useCorporations"
import useModal from "../../hooks/useModal"
import useStorages from "../../hooks/useStorages"
import useUniversities from "../../hooks/useUniversities"
import Storage from "../../types/Storage"
import ControlledGoogleAutoComplete from "../common/ControlledGoogleAutoComplete"
import ControlledInput from "../common/ControlledInput"
import CustomButton from "../common/CustomButton"
import ModalHeader from "../common/ModalHeader"
import Alert, { AlertModalProps } from "./Alert"

export type AddStorageModalProps = {
    storage: Storage
}

const AddStorage = ({ hide, props }: { hide: () => void, props: AddStorageModalProps }) => {

    const { show: showAlertModal, ModalComp: AlertModal } = useModal<AlertModalProps>(({ hide, props }) => <Alert hide={hide} props={props} />)

    const { register, control, handleSubmit, formState: { errors, isDirty } } = useForm<Storage>({ defaultValues: props.storage })

    const { updateStorage, addStorage, deleteStorage } = useStorages()

    const onSubmit: SubmitHandler<Storage> = (data) => {
        console.log(data)
        if (props.storage) {
            updateStorage.mutate(data, {
                onSuccess: hide
            })
        } else {
            addStorage.mutate(data, {
                onSuccess: hide
            })
        }
    }

    const onDelete = () => {
        showAlertModal({
            title: 'Delete',
            subTitle: 'Are you sure you want to delete?',
            buttons: [{ text: 'Cancel' }, { text: 'Yes', onClick: () => (deleteStorage.mutate(props.storage.id), hide()) }]
        })
    }

    return (
        <div style={{ margin: -30, width: 600 }}>
            {AlertModal}
            <ModalHeader style={{ padding: 30, paddingBottom: 10 }} hide={hide} title="Warehouse Details" subtitle="Update warehouse details details" />

            <div style={{ height: 1, background: colors.BLACK_20 }} />

            <div style={{ overflowY: 'scroll', flex: 1, display: 'flex', flexDirection: 'column', paddingTop: 20, maxHeight: '75vh', padding: 30 }} >
                <ControlledInput
                    label='Name'
                    inputProps={{ placeholder: 'Add warehouse name here...' }}
                    containerStyle={{ marginBottom: 15 }}
                    formProps={{ register, errors, name: 'name', options: { required: true } }} />

                <ControlledGoogleAutoComplete
                    label='Location'
                    containerStyle={{ marginBottom: 15, flex: 1 }}
                    formProps={{ errors, name: `location`, options: { required: true }, control }} />

            </div>

            <div style={{ height: 1, background: colors.BLACK_20 }} />

            <div style={{ display: 'flex', alignItems: 'center', padding: 20, gap: 20 }} >
                <CustomButton
                    loading={addStorage.isLoading}
                    onClick={handleSubmit(onSubmit)}
                    containerStyle={{ flex: 1 }}
                    label={props.storage ? "Update" : "Create"} />
                {props.storage && <CustomButton
                    onClick={onDelete}
                    loading={deleteStorage.isLoading}
                    containerStyle={{ background: colors.RED, paddingLeft: 40, paddingRight: 40 }}
                    label="Delete" />}
            </div>

        </div>
    )
}

export default AddStorage
