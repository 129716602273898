import { useMutation } from "@tanstack/react-query"
import { db } from "../config/firebase"
import { Corporate } from "../types/Corporate"
import Event from "../types/Event"
import { University } from "../types/University"
import useFirestoreRealtimeData from "./useFirestoreRealtimeData"

const universitiesColRef = db.collection('universities')

const useUniversities = () => {

    return {
        useUniversitiesListener: () => useFirestoreRealtimeData({
            query: universitiesColRef,
            select: snapshot => (snapshot?.docs.map(doc => ({ id: doc.id, ...doc.data() } as University)) || []).sort((a, b)=>b.created_at - a.created_at)
        }),

        addUniversity: useMutation({
            mutationFn: async (data: Partial<University>) => universitiesColRef.add({...data, created_at : Date.now(), status: 'active' })
        }),

        updateUniversity: useMutation({
            mutationFn: async (data: Partial<University>) => universitiesColRef.doc(data.id || 'alt').update(data)
        }),

        deleteUniversity: useMutation({
            mutationFn: async (id :string) => universitiesColRef.doc(id).delete()
        })
    }
}

export default useUniversities