import { useQuery } from "@tanstack/react-query";
import { collection, getCountFromServer, limit, query } from "firebase/firestore";
import { firestoreDb } from "../config/firebase";

const usersColRef = collection(firestoreDb, "users");

const useUsers = () => {
  return {
    useAllUsersCount: () =>
      useQuery({
        queryKey: ["all-users"],
        queryFn: async () => {
          const res = await getCountFromServer(query(usersColRef));
          return res.data().count;
        },
      }),
  };
};

export default useUsers;
