const Icons = {
    carbon : require('./carbon.png'),
    stat_shoulder : require('./stat_shoulder.png'),
    hexagon : require('./hexagon.png'),
    fuel : require('./fuel.png'),
    car_top : require('./fuel.png'),
    wallet : require('./wallet.png'),
    rocket : require('./rocket.png'),
    arrow_left_circle : require('./arrow_left_circle.png'),
    calendar : require('./calendar.png'),
    logout : require('./logout.png'),
    switch_accounts : require('./switch_accounts.png'),
    personal_account : require('./personal_account.png'),
    event_account : require('./event_account.png'),
    corporate_account : require('./corporate_account.png'),
    hexagon_2 : require('./hexagon_2.png'),
    add_image : require('./add_image.png')
}

export default Icons