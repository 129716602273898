import { SubmitHandler, useForm } from "react-hook-form";
import colors from "../../assets/colors";
import useCorporations from "../../hooks/useCorporations";
import useModal from "../../hooks/useModal";
import useUniversities from "../../hooks/useUniversities";
import { University } from "../../types/University";
import ControlledGoogleAutoComplete from "../common/ControlledGoogleAutoComplete";
import ControlledInput from "../common/ControlledInput";
import CustomButton from "../common/CustomButton";
import ModalHeader from "../common/ModalHeader";
import Alert, { AlertModalProps } from "./Alert";
import CustomCheckbox from "../common/CustomCheckbox";
import { useToggle } from "react-use";
import ControlledFileInput from "../common/ControlledFileInput";

export type AddUniversityModalProps = {
  university: University;
};

const AddUniversity = ({
  hide,
  props,
}: {
  hide: () => void;
  props: AddUniversityModalProps;
}) => {
  const { show: showAlertModal, ModalComp: AlertModal } =
    useModal<AlertModalProps>(({ hide, props }) => (
      <Alert hide={hide} props={props} />
    ));

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<University>({ defaultValues: props.university });

  const [addLogo, toggleLogoInput] = useToggle(!!props?.university?.logo);

  const { updateUniversity, addUniversity, deleteUniversity } =
    useUniversities();

  const onSubmit: SubmitHandler<University> = (data) => {
    console.log(data);
    if (props.university) {
      updateUniversity.mutate(data, {
        onSuccess: hide,
      });
    } else {
      addUniversity.mutate(data, {
        onSuccess: hide,
      });
    }
  };

  const onDelete = () => {
    showAlertModal({
      title: "Delete",
      subTitle: "Are you sure you want to delete?",
      buttons: [
        { text: "Cancel" },
        {
          text: "Yes",
          onClick: () => (deleteUniversity.mutate(props.university.id), hide()),
        },
      ],
    });
  };

  return (
    <div style={{ margin: -30, width: 600 }}>
      {AlertModal}
      <ModalHeader
        style={{ padding: 30, paddingBottom: 10 }}
        hide={hide}
        title="Institution Details"
        subtitle="Update institution details details"
      />

      <div style={{ height: 1, background: colors.BLACK_20 }} />

      <div
        style={{
          overflowY: "scroll",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingTop: 20,
          maxHeight: "75vh",
          padding: 30,
        }}
      >
        <ControlledInput
          label="Name"
          inputProps={{ placeholder: "Add institution name here..." }}
          containerStyle={{ marginBottom: 15 }}
          formProps={{
            register,
            errors,
            name: "name",
            options: { required: true },
          }}
        />

        <ControlledGoogleAutoComplete
          label="Location"
          containerStyle={{ marginBottom: 15, flex: 1 }}
          formProps={{
            errors,
            name: `location`,
            options: { required: true },
            control,
          }}
        />

        <div>
          <CustomCheckbox
            containerStyle={{ marginBottom: 15 }}
            value={addLogo}
            label="Add Institution Logo"
            onClick={toggleLogoInput}
          />
          {addLogo && (
            <ControlledFileInput
              containerStyle={{ marginBottom: 15, width: 70 }}
              formProps={{
                errors,
                name: "logo",
                options: { required: false },
                control,
              }}
            />
          )}
        </div>
      </div>

      <div style={{ height: 1, background: colors.BLACK_20 }} />

      <div
        style={{ display: "flex", alignItems: "center", padding: 20, gap: 20 }}
      >
        <CustomButton
          loading={addUniversity.isLoading}
          onClick={handleSubmit(onSubmit)}
          containerStyle={{ flex: 1 }}
          label={props.university ? "Update" : "Create"}
        />
        {props.university && (
          <CustomButton
            onClick={onDelete}
            loading={deleteUniversity.isLoading}
            containerStyle={{
              background: colors.RED,
              paddingLeft: 40,
              paddingRight: 40,
            }}
            label="Delete"
          />
        )}
      </div>
    </div>
  );
};

export default AddUniversity;
