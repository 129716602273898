
import React, { useMemo } from 'react'
import { FieldErrorsImpl, Path, RegisterOptions, Controller, Control, ControllerRenderProps, ControllerFieldState, UseFormStateReturn, FieldValues } from "react-hook-form";
import colors from '../../assets/colors';
import { usePlacesWidget } from 'react-google-autocomplete';
import * as geofire from 'geofire-common';
// import "https://maps.googleapis.com/maps/api/js?key=AIzaSyDU-x2X01IBDtN6bt4xvW_2uhNmRt8m1AA&libraries=places&callback=initMap";

type Props<Fields extends FieldValues> = {
    containerStyle?: React.CSSProperties
    label: string
    inputProps?: React.InputHTMLAttributes<any>
    formProps: {
        errors: Partial<FieldErrorsImpl<{ [x: string]: any; }>>
        name: Path<FieldValues>
        options?: Partial<RegisterOptions>
        control: Control<any, any>
        isDirty?:boolean
    }
}

const ControlledGoogleAutoComplete = <Fields extends FieldValues,>({ label, formProps, containerStyle, inputProps }: Props<Fields>) => {

    const errorMessage = useMemo(() => {
        const error = formProps.errors[formProps.name]
        if (!error) return ''
        if (error?.message) return error.message + ''
        if (error.type == 'pattern') return `Please enter a valid ${label}`
        return `${label} is required`
    }, [formProps.errors[formProps.name]])

    const ControllerRender = ({ field: { onChange, onBlur, value }, fieldState, formState, }: {
        field: ControllerRenderProps<any, Path<FieldValues>>;
        fieldState: ControllerFieldState;
        formState: UseFormStateReturn<any>;
    }) => {
        const { ref } = usePlacesWidget({
            apiKey: 'AIzaSyDU-x2X01IBDtN6bt4xvW_2uhNmRt8m1AA',
            // inputAutocompleteValue: "dakjnkj",
            options: { types: ['establishment']},
            onPlaceSelected: (place) => {
                onChange({
                    coordinates: {
                        latitude: place.geometry.location.lat(),
                        longitude: place.geometry.location.lng()
                    },
                    address: place.formatted_address,
                    geohash: geofire.geohashForLocation([place.geometry.location.lat(), place.geometry.location.lng()])
                })
                console.log(place)
            }
        })

        return (
            <input
                {...inputProps}
                ref={ref}
                defaultValue={value?.address}
                style={{ backgroundColor: colors.BLUE + '12', borderRadius: 5, padding: 12, borderWidth: 0, minWidth: 200, paddingLeft: 16, paddingRight: 16, alignSelf: 'stretch' }} />
        )
    }


    return (
        <div
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', alignSelf: 'stretch', ...containerStyle }} >
            <p style={{ color: colors.BLACK_100, fontSize: 12,fontWeight : '500', marginBottom: 6 }} >{label}</p>
            <Controller
                name={formProps.name}
                control={formProps.control}
                rules={formProps.options}
                render={ControllerRender} />

            {errorMessage && <p style={{ color: 'red', fontSize: 12, marginTop: 5 }} >{errorMessage}</p>}

        </div>
    )
}

export default ControlledGoogleAutoComplete