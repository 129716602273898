import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { MdOutlineCancel } from "react-icons/md";
import colors from "../../assets/colors";
import useCorporations from "../../hooks/useCorporations";
import useModal from "../../hooks/useModal";
import { Corporate } from "../../types/Corporate";
import ControlledGoogleAutoComplete from "../common/ControlledGoogleAutoComplete";
import ControlledInput from "../common/ControlledInput";
import CustomButton from "../common/CustomButton";
import ModalHeader from "../common/ModalHeader";
import Alert, { AlertModalProps } from "./Alert";
import CustomCheckbox from "../common/CustomCheckbox";
import { useToggle } from "react-use";

export type AddCorporateModalProps = {
  corporate: Corporate;
};

const AddCorporate = ({
  hide,
  props,
}: {
  hide: () => void;
  props: AddCorporateModalProps;
}) => {
  const { show: showAlertModal, ModalComp: AlertModal } =
    useModal<AlertModalProps>(({ hide, props }) => (
      <Alert hide={hide} props={props} />
    ));

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Corporate>({ defaultValues: props.corporate });
  const { fields, append, remove } = useFieldArray({
    name: "branches",
    control,
  });
  const { updateCorporation, addCorporation, deleteCorporation } =
    useCorporations();

  const onSubmit: SubmitHandler<Corporate> = (data) => {
    console.log(data);
    if (props.corporate) {
      updateCorporation.mutate(JSON.parse(JSON.stringify(data)), {
        onSuccess: hide,
      });
    } else {
      addCorporation.mutate(JSON.parse(JSON.stringify(data)), {
        onSuccess: hide,
      });
    }
  };

  const onDelete = () => {
    showAlertModal({
      title: "Delete",
      subTitle: "Are you sure you want to delete?",
      buttons: [
        { text: "Cancel" },
        {
          text: "Yes",
          onClick: () => (deleteCorporation.mutate(props.corporate.id), hide()),
        },
      ],
    });
  };

  return (
    <div style={{ margin: -30, width: 600 }}>
      {AlertModal}
      <ModalHeader
        style={{ padding: 30, paddingBottom: 10 }}
        hide={hide}
        title="Corporate Details"
        subtitle="Update corporate details"
      />

      <div style={{ height: 1, background: colors.BLACK_20 }} />

      <div
        style={{
          overflowY: "scroll",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingTop: 20,
          maxHeight: "75vh",
          padding: 30,
        }}
      >
        <ControlledInput
          label="Name"
          inputProps={{ placeholder: "Add corporate name here..." }}
          containerStyle={{ marginBottom: 15 }}
          formProps={{
            register,
            errors,
            name: "name",
            options: { required: true },
          }}
        />

        <div
          style={{
            gap: 10,
            display: "flex",
            flexDirection: "column",
            marginBottom: 15,
          }}
        >
          {fields.map((field, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  padding: 15,
                  border: "dashed #ccc 1px",
                  borderRadius: 8,
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex", gap: 12, flexDirection: "row" }}>
                  <MdOutlineCancel
                    onClick={() => remove(index)}
                    className="hover-tint hover-border"
                    style={{
                      padding: 8,
                      color: colors.BLACK_80,
                      background: colors.BLACK_10,
                      width: 40,
                      marginTop: 22,
                      borderRadius: 5,
                      height: 40,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />

                  <ControlledInput
                    key={field.id}
                    label="Name"
                    inputProps={{ placeholder: "Add office name here..." }}
                    containerStyle={{ marginBottom: 15, flex: 1 }}
                    formProps={{
                      register,
                      errors,
                      name: `branches.${index}.name`,
                      options: { required: true },
                    }}
                  />

                  <ControlledGoogleAutoComplete
                    label="Location"
                    containerStyle={{ marginBottom: 15, flex: 1 }}
                    formProps={{
                      errors,
                      name: `branches.${index}.location`,
                      options: { required: true },
                      control,
                    }}
                  />
                </div>

                <Controller
                  control={control}
                  name={`branches.${index}.minting_enabled`}
                  render={({ field: { onChange, value } }) => (
                    <CustomCheckbox
                      label="Enable Minting Proof of Impact NFT"
                      value={value}
                      onClick={() => onChange(!value)}
                    />
                  )}
                />
              </div>
            );
          })}
        </div>

        <CustomButton
          secondary
          onClick={() => append({ name: "" })}
          label="Add Branch"
        />
      </div>

      <div style={{ height: 1, background: colors.BLACK_20 }} />

      <div
        style={{ display: "flex", alignItems: "center", padding: 20, gap: 20 }}
      >
        <CustomButton
          loading={addCorporation.isLoading}
          onClick={handleSubmit(onSubmit)}
          containerStyle={{ flex: 1 }}
          label={props.corporate ? "Update" : "Create"}
        />
        {props.corporate && (
          <CustomButton
            onClick={onDelete}
            loading={deleteCorporation.isLoading}
            containerStyle={{
              background: colors.RED,
              paddingLeft: 40,
              paddingRight: 40,
            }}
            label="Delete"
          />
        )}
      </div>
    </div>
  );
};

export default AddCorporate;
