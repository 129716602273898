import React, { CSSProperties, ReactNode, useMemo, useState } from "react";
import Modal from 'react-modal';

import colors from "../assets/colors";

const useModal = <TProps,>(content: ({ hide, visible, props }: { hide: () => void, props?: TProps, visible?:boolean }) => ReactNode, defaultVisible = false) => {

    const [props, setProps] = useState<TProps>()

    const [visible, setVisibility] = useState(defaultVisible)

    const show = (props?: TProps) => {
        setProps(props)
        setVisibility(true)
    }
    const hide = () => setVisibility(false)


    return {
        show, hide, ModalComp: (
            <Modal
                ariaHideApp={false}
                isOpen={visible}
                onRequestClose={hide}
                closeTimeoutMS={200}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {content({ hide,visible, props })}
            </Modal>
        )
    }
}

export default useModal

const customStyles: { content: CSSProperties, overlay: CSSProperties } = {
    content: {
        top: 'auto',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        background: colors.WHITE,
        borderRadius: 15,
        padding: 30
    },
    overlay: {
        background: '#00000025',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};
