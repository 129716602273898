import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { AuthProvider } from "./useAuth"

const queryClient = new QueryClient()

export const Providers = ({ children }) => {
    return (
        <QueryClientProvider client={queryClient} >
            <AuthProvider>
                {children}
            </AuthProvider>
        </QueryClientProvider>

    )
}